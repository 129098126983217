
import React, { useState } from 'react';
import './fonts.css';
import './MarqueeText.css';
import spiral from './img/spiral.png';
import logo from './img/logo.png';
import group from './img/group.png';
import './media.css';
import arrowright from './img/arrow-right.png';
import maskgrouptop from './img/maskgrouptop.png';
import person from './img/person.png';
import maska from './img/image_2023-08-19_19-25-25.png';
import telegram from './img/telegram.png';
import be from './img/behance.png';
import dribbble from './img/dribbble-icon.png';
import arrowRight from './img/arrowRight.png';
import stars from './img/stars.png';
import personTwo from './img/personTwo.png';
import fourWhole from './img/fourWhole.png';
import thornyExp from './img/thornyExp.png';
import aboutDec from './img/aboutDec.png';
import ContactUsModal from './Modal.js';
import TechnologiesCardsSwitch from './TechnologiesCardsSwitch/TechnologiesCardsSwitch';
import ProgrammingLanguageSwitch from './ProgrammingLanguageSwitch/ProgrammingLanguageSwitch';
function App() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  function handleAccordionClick(event, index) {
    const accordion = event.currentTarget; // Отримання всього елемента акордеону
    const panel = accordion.nextElementSibling;
    const isActive = accordion.classList.contains('active');

    closeAllPanels();

    if (!isActive) {
      setActiveAccordion(index);
      accordion.classList.add('active');
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  function closeAllPanels() {
    const panels = document.querySelectorAll('.panel');
    const accordions = document.querySelectorAll('.accordionn');

    for (let i = 0; i < panels.length; i++) {
      panels[i].style.maxHeight = null;
    }

    for (let j = 0; j < accordions.length; j++) {
      accordions[j].classList.remove('active');
    }
  }
  return (
    <div className="App">
      <div className="black_background_login">
        <div className="user_login">
          <img src={logo} className="logo" alt="Logo" />
          <p className="contact"> +380 97 840 93 41 </p>

          <ContactUsModal className="contactUsModal">
            <div className="imageButton">Contact us</div>
          </ContactUsModal>
        </div>
        <div className="top_text">
          <p className="bringing_digital">
            Building & bringing digital
            <img src={group} className="group" alt="group" />
            solutions from scratch
          </p>
          <p className="welcome">
            Welcome to the RTC - Mastering Complexity Across Domains for Your
            Digital Success
          </p>
          <button className="learn_more">Learn more</button>
        </div>

        <div className="first_block">
          <img src={spiral} className="spiral" alt="Spiral" />
        </div>
      </div>
      <div className="second_block">
        <div className="marquee-container">
          <ContactUsModal className="contactUsModal" style="height: 50px;">
            <p className="marquee-text">
              <span className='running-modal'>
                contact us <img className='arrow-img' src={arrowright}></img>
              </span>
              <span className='running-modal'>
                contact us <img className='arrow-img' src={arrowright}></img>
              </span>
              <span className='running-modal'>
                contact us <img className='arrow-img' src={arrowright}></img>
              </span>
              <span className='running-modal'>
                contact us <img className='arrow-img' src={arrowright}></img>
              </span>
            </p>
          </ContactUsModal>
        </div>
      </div>
      <div className="maskgrouptoptex">
        <div>
          <img src={maskgrouptop} className="maskgrouptop"></img>
          <div className="why_us_text"> Why us?</div>
        </div>
        <div className="why_us_blok">
          <div className="row_why_us ">
            <div className="complex_minds_div">
              <p className="turning_text">
                Turning your complex minds into digital solutions
              </p>
            </div>
            <div className="rating_div">
              <div className="grid-row-one">
                <div className="grid-item-rating-one">
                  <button className="agileDevelopmentButton">
                    Agile Development
                  </button>
                </div>
                <div className="grid-item-rating-two">
                  <p className="grid-item-rating-text-01"></p>
                </div>
              </div>
              <div className="grid-row-two">
                <div className="grid-item-rating-three"></div>
                <div class="grid-item-rating-four">
                  <div className="union">
                    <div className="averagePoint">Average point</div>
                    <p className="fourWhole_text">
                      <img src={fourWhole} className="fourWhole"></img>
                      <p className="points_text">20+ points</p>
                    </p>
                  </div>
                </div>
                <div className="grid-item-rating-five">
                  <div className="grid-item-container">
                    <img src={person} className="personreview_img" />

                    <img src={stars} className="stars_img" />
                  </div>
                </div>
              </div>
              <div className="grid-row">
                <div className="grid-item-rating">
                  <p className="navigation_performance">
                    Our team crafts websites and apps that guarantee seamless
                    navigation and optimal performance across various devices.
                  </p>
                </div>

                <div className="grid-item-rating">
                  <div className="personTwo-container">
                    <img src={personTwo} className="personreview_img" />
                    <p className="people_team">3 people in team</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="unique_requirements_div">
              <div className="feedback_text">
                By understanding your unique requirements, we ensure your online
                presence.
              </div>
              <div className="feedback_texttext_numeric"></div>
              <div className="feedback">
                <div className="feedback_block">
                  <p className="amount_coffee">1500+</p>
                  <p className="amount_coffee_text">
                  Cups of<br></br>coffee drunk
                  </p>
                </div>
                <div class="feedback_block">
                  <p className="amount_coffee">3</p>
                  <p className="amount_coffee_text">projects</p>
                </div>
              </div>
            </div>
            <div className="reality_blue_div">
              <div className="feedback_text">
                We ensure your digital venture becomes a reality without delays.
              </div>
              <div className="feedback_texttext_numeric03"></div>
              <div className="feedback">
                <div className="feedback_block">
                  <p className="amount_coffee">3</p>
                  <p className="amount_coffee_text">employees</p>
                </div>
                <div className="feedback_block">
                  <p className="amount_coffee">4+</p>
                  <p className="amount_coffee_text">
                  years of experience
                  </p>
                </div>
              </div>
            </div>
            <div className="high_development_div">
              <div className="feedback_text_five">
                We provide a high level of development and help you both during
                and after the end of the project.
              </div>

              <div className="feedback">
                <div className="feedback_block_five">
                  <p className="feedback_texttext_numeric04">04</p>
                </div>
                <div className="feedback_block_black">
                  <p className="works_progress_text">Works in progress 🔄</p>
                  <div classn="grid-container-progress">
                    <div className="grid-row-progress">
                      <div className="grid-item-progress">
                        <div className="small-white-circle">
                          <svg
                            className="mall-white-circleTex"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <circle cx="7.5" cy="7.5" r="7.5" fill="white" />
                          </svg>
                        </div>
                      </div>
                      <div className="grid-item-progress">
                        <p className="mini_text_progress">
                          Development of a <br/> CRM system
                        </p>
                      </div>
                      <div className="grid-item-progress">
                        <div className="feedback_rating">
                        <div className='progressBar'>
                          <div className='myBar1'></div>
                        </div>
                    
                        </div>
                        <p className="percentages">
                          <span className="percentage-value">70</span> /
                          100 %
                        </p>
                      </div>
                    </div>
                    <div className="grid-row-progress">
                      <div className="grid-item-progress">
                        <div className="small-white-circle">
                          <svg
                            className="mall-white-circleTex"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <circle cx="7.5" cy="7.5" r="7.5" fill="white" />
                          </svg>
                        </div>
                      </div>
                      <div className="grid-item-progress">
                        <p className="mini_text_progress">E-commerce project</p>
                      </div>
                      <div className="grid-item-progress">
                        <div className="feedback_rating">
                        <div className='progressBar'>
                          <div className='myBar2'></div>
                        </div>
                        </div>
                        <p className="percentages">
                          <span className="percentage-value">65</span> /
                          100 %
                        </p>
                      </div>
                    </div>
                    <div className="grid-row-progress">
                      <div className="grid-item-progress">
                        <div className="small-white-circle">
                          <svg
                            className="mall-white-circleTex"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <circle cx="7.5" cy="7.5" r="7.5" fill="white" />
                          </svg>
                        </div>
                      </div>
                      <div className="grid-item-progress">
                        <p className="mini_text_progress">
                          Making a world better
                        </p>
                      </div>
                      <div className="grid-item-progress">
                        <div className="feedback_rating">
                        <div className='progressBar'>
                          <div className='myBar3'></div>
                        </div>
                        </div>
                        <p className="percentages">
                          <span className="percentage-value">92</span> /
                          100 %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lets_discuss_div">
              <ContactUsModal className="contactUsModal">
                <p className="lets_discuss_text">
                  <span className='letsDiscuss'>
                    lets discuss! <img className='letsDiscussArrow' src={arrowRight} alt="Arrow" />
                  </span>
                </p>
              </ContactUsModal>
            </div>
          </div>
        </div>
      </div>
      {/* друга бігуча лінія */}
      <div className="fourth_block">
        <div className="marquee-container_fourth">
          <ContactUsModal className="contactUsModal">
          <p className="marquee-text">
            <p className="marquee-text_fourth">Innovation changes everything</p>
            <p className="marquee-text_fourth">Innovation changes everything</p>
            </p>
          </ContactUsModal>
        </div>
      </div>
      {/* технології */}
      <div className="our_technologies">
        <div className="row_block_technologi">
          <div className="left_text_technologi">
            Tools we <br></br> work with
          </div>
          <div className='button_technologies_div'>
          <ContactUsModal className="contactUsModal">
            <button className="button_technologies">Contact us</button>
          </ContactUsModal>
          </div>
        </div>

        <div className="row_block_technologi">
          <div className="single_text_technologies">Cloud:</div>
        </div>

        <div className="row_block_technologi_clouds">
          <TechnologiesCardsSwitch />
        </div>

        <div className="row_block_technologi">
          <div className="single_text_technologies">Programming languages:</div>
        </div>

        <ProgrammingLanguageSwitch />

        <div className="maska">
          <img src={maska}></img>
        </div>
        <div className="left_text_services">Services</div>
        <div className="menu">
          <div
            className={`accordionn ${activeAccordion === 0 ? 'active' : ''}`}
            onClick={(event) => handleAccordionClick(event, 0)}
          >
            Web, Desktop, Mobile Development
          </div>
          <div className="panel">
            <div className="panel_texs">
              Unleash the potential of your ideas through our holistic approach
              to development. Web, desktop, mobile – we engineer dynamic
              solutions that redefine user experiences across the digital
              landscape. Web development deals with online sites, desktop
              development involves programs on your computer, and mobile
              development creates apps for your phones and tablets. Each type of
              development ensures the technology works smoothly and is
              user-friendly on the respective devices.
            </div>
          </div>

          <div
            className={`accordionn ${activeAccordion === 0 ? 'active' : ''}`}
            onClick={(event) => handleAccordionClick(event, 0)}
          >
            Quality Assurance
          </div>
          <div className="panel">
            <div className="panel_texs">
              Guardians of perfection. We meticulously test and inspect to
              guarantee flawless performance, providing products you can trust.
              Quality assurance is like having a careful inspector for products
              or services. Just as a chef tastes every dish before serving,
              quality assurance makes sure things are just right. It involves
              thorough checking, testing, and fixing to make sure what you get
              is dependable and meets high standards. It's like a seal of
              approval that ensures you're getting the best.
            </div>
          </div>

          <div
            className={`accordionn ${activeAccordion === 0 ? 'active' : ''}`}
            onClick={(event) => handleAccordionClick(event, 0)}
          >
            Product Support
          </div>
          <div className="panel">
            <div className="panel_texs">
              Always here to help. Our product support ensures you're never
              alone with questions or issues. We're your reliable partner on
              your product journey. Product support is like having a friendly
              problem-solver always ready to assist you. When you use something
              – whether it's a gadget, software, or item – and encounter
              questions or issues, product support steps in. They provide
              answers, guidance, and solutions to make sure you have a smooth
              and satisfying experience with that product.
            </div>
          </div>

          <div
            className={`accordionn ${activeAccordion === 0 ? 'active' : ''}`}
            onClick={(event) => handleAccordionClick(event, 0)}
          >
            IT Consulting
          </div>
          <div className="panel">
            <div className="panel_texs">
              Your technology guide. We offer IT consulting to navigate
              complexities, suggesting solutions that help your business use
              technology effectively. IT consulting is like having a
              knowledgeable friend for your technology questions. We help you
              decide which gadgets and systems suit your needs. Just as a travel
              advisor plans your trip, we guide you through the tech world,
              suggesting solutions that fit your goals and make your digital
              life smoother.
            </div>
          </div>

          <div
            className={`accordionn ${activeAccordion === 0 ? 'active' : ''}`}
            onClick={(event) => handleAccordionClick(event, 0)}
          >
            UI/uX Design
          </div>
          <div className="panel">
            <div className="panel_texs">
              Designing with users in mind. Our UI/UX service focuses on
              creating seamless, visually appealing experiences that delight and
              engage. UI/UX design is like creating a comfortable and enjoyable
              space for you in the digital world. UI (User Interface) focuses on
              how things look, arranging buttons and colors like furniture in a
              room. UX (User Experience) considers how easy it is to move around
              that room – making sure you find everything effortlessly and have
              a pleasant time.
            </div>
          </div>
        </div>
      </div>
      <div className="our_team">

        <div className='decisions-block'>
          <div className='thorny-decision'>
            <div className='thorny-expirience-description'>
              <h2 className='decisions-title'>thorny experience</h2>
              <p className='decisions-text'>Digital empowerment</p>
              <p className='decisions-description'>Our team of seasoned IT professionals brings 
              a wealth of experience to the table. From cutting-edge software development to 
              robust cybersecurity solutions, we've got the knowledge and skills to
              address your unique challenges.
              </p>
              <div className='thorny-button-block'>
              <ContactUsModal className="contactUsModal">
                <button className="decisions-button">Contact us</button>
              </ContactUsModal>
            </div>
            </div>
            <div className='thorny-expirience-image'>
              <img src={aboutDec} className='desicions-image'/>
            </div>
          </div>
          <div className='thorny-decision'>
          <div className='about-decisions-description'>
              <h2 className='decisions-title'>About decisions</h2>
              <p className='decisions-text'>Tailored IT Solutions</p>
              <p className='decisions-description'>We provide customized IT solutions 
              tailored to your business goals, ensuring you get what you need. We stay 
              ahead of industry trends and emerging technologies to keep your business
               competitive and adaptable.
               </p>
               <div className='decisions-button-block'>
              <ContactUsModal className="contactUsModal">
                <button className="decisions-button">Contact us</button>
              </ContactUsModal>
              </div>
            </div>
            <div className='about-decisions-image'>
              <img src={thornyExp} className='desicions-image'/>
            </div>
          </div>
        </div>

        {/* про команду */}
        <div className="yellow_block">
          <div className="container_yellow_text">
            <div className="grid">
              <div className="block">
                <p className="block_text1">Get in touch</p>
              </div>
              <div className="block">
                <p className="block_text2">
                  We know how to unlock the potential of your business. Click
                  the button on the right and we immediately call you back.
                </p>
              </div>
            </div>
            <div className="block3">
              <ContactUsModal className="contactUsModal">
                <button className="contact_us">Contact us</button>
              </ContactUsModal>
            </div>
          </div>
        </div>
        <div className="bottom_site">
          <div className="block_bottom_site1">
            <div className="block_bottom_site_rts">
              <img className='block_bottom_logo' src={logo} alt="Logo" />
            </div>
            <p className="block_bottom_site_text_small2">
              Welcome to the RTC - Mastering Complexity Across Domains for Your
              Digital Success. We provide your dreams into digital solutions
            </p>
          </div>
          <div className="block_bottom_site2">
            <p className="block_bottom_site_text">Services</p>
            <p className="block_bottom_site_text_small">
              Web, Desktop, Mobile Development
            </p>
            <p className="block_bottom_site_text_small">Quality Assurance</p>
            <p className="block_bottom_site_text_small">Product Support</p>
            <p className="block_bottom_site_text_small">IT Consulting</p>
            <p className="block_bottom_site_text_small">UI/UX Design</p>
          </div>
          <div className="block_bottom_site3">
            <p className="block_bottom_site_text">Company</p>
            <p className="block_bottom_site_text_small">Meet our team</p>
            <p className="block_bottom_site_text_small">
              Portfolio (in proccess)
            </p>
          </div>
          <div class="block_bottom_site4">
            <p className="block_bottom_site_text">Tools</p>
            <p className="block_bottom_site_text_small">Clouds</p>
            <p className="block_bottom_site_text_small">
              Programming languages
            </p>
          </div>
        </div>
        <div className="end_site">
          <div className="copyright1">
            <p className="block_bottom_site_text_small_footer">
              ©2023 rtc. All Rights Reserved.
            </p>
          </div>
          <div className="copyright2">
            <img src={telegram} className="retreat_copyright" />
            <img src={be} className="retreat_copyright" />
            <img src={dribbble} className="retreat_copyright" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
