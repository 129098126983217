import React, { useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import './modal.css';
import logo from './img/logo.png';
import rectangle from './img/rectangle.png';
import axios from 'axios';
import { useEffect } from 'react';


export default function ContactUsModal(props) {


  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [contactData, setContactData] = useState('');
  const [description, setDescription] = useState('')
  const [data, setData] = useState('');

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [successSumbit, setSuccessSumbit] = useState(false);
  const [contactMethod, setContactMethod] = useState("Email");

  const subtitle = useRef(); // Використовуємо useRef для посилання на елемент

  useEffect(() => {
    setContactData(contactMethod === "Email" ? "Email address" : "Phone number or username" )
  },[contactMethod])

  function handleClick(e) {
    let dataType = contactMethod === "Email" ? {"email": contactData, "phoneNumber": '' } : {"email": '', "phoneNumber": contactData}
    axios.post(`${window.location.origin}/Api`, {
      "name": name, 
      "contactMethod": contactMethod, 
      ...dataType,
      "projectDescription": description}).then(res => console.log(res))
    setSuccessSumbit(true);
  }

  const handleChange = (e) => {
    setContactData(e.target.value)
    
  }

  const clearInput = () => {
    setContactData('')
  }


  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <div className="contactUsModal" onClick={openModal}>
        {props.children}
      </div>
      <Modal
        open={modalIsOpen}
        close={closeModal}
       
        contentLabel="Example Modal"
      ><Box  className="main-modal">
        <div className="containerLogoModal">
          <img src={logo} className="logoModal" alt="Logo" />
          <div className='modal-title'>
          <p className="contactModal"> +380 97 840 93 41 </p>
          <button className="buttonModal" onClick={closeModal}>
            <p className='buttonModalFlex'>
              {' '}
              Close &nbsp; &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
              >
                <path
                  d="M1 8L7.99997 1.00003"
                  stroke="white"
                  strokeOpacity="0.6"
                  strokeWidth="1.23743"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.99997 7.99997L1 1"
                  stroke="white"
                  strokeOpacity="0.6"
                  strokeWidth="1.23743"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </p>
          </button>
          </div>
        </div>

        {successSumbit ? (
          <>
            <div className="implementation">
              <div className="implementationSvg">
                <svg 
                  className='svg-icon-modal'
                  xmlns="http://www.w3.org/2000/svg"
                  width="59"
                  height="59"
                  viewBox="0 0 59 59"
                  fill="none"
                >
                  <path
                    d="M48.6835 15.9731C47.0532 14.3423 44.409 14.3423 42.7785 15.9731L24.1324 34.6192L16.9626 27.4494C15.3321 25.8186 12.6879 25.8186 11.0571 27.4494C9.42629 29.0802 9.42629 31.7241 11.0571 33.3549L21.1797 43.4775C21.9951 44.2929 23.0639 44.7008 24.1321 44.7008C25.2004 44.7008 26.2695 44.2931 27.0846 43.4775L48.6832 21.8789C50.3143 20.2478 50.3143 17.6039 48.6835 15.9731Z"
                    fill="#F4F4F4"
                  />
                </svg>
              </div>
              <div className="implementationText">
                Thanks! We will contact you soon!
              </div>
              <button onClick={() => setSuccessSumbit(false)} className="implementationButton">close</button>
            </div>
          </>
        ) : (
          <>
            <div className="containerModal">
              <div className="textModal">
                <p className="contacUsModal">CONTACT US</p>
                <input
                  type="text"
                  className="textBlocModal"
                  name="kye"
                  placeholder=" Your name "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
               <select value={contactMethod} onChange={(e) => setContactMethod(e.target.value)} className="textBlocModal"  name="select">
                <option value="Email">Email</option>
                <option value="Telegram">Telegram</option>
              </select>
                <input
                  type="text" 
                  className="textBlocModal"
                  name="kye"
                  value={contactData} 
                  onClick={clearInput}
                  onChange={handleChange}
                ></input>
                <input
                  type="text"
                  placeholder="A few words about your project"
                  className="textBlocModal"
                  name="kye"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></input>

                <button className="buttonBlocModal" onClick={handleClick}>
                  {' '}
                  Submit{' '}
                </button>
                <p className='privacy-policy-main'>
                  <span className="privacy-policy-text">
                    By submitting the form, you agree to the
                  </span>{' '}
                  <a className="privacy-policy-link" href="#">
                    privacy policy
                  </a>
                  .
                </p>
              </div>
              <img className="rectangleModal" src={rectangle} alt="Rectangle" />
            </div>
          </>
        )}
        </Box>
      </Modal>
    </div>
  );
}
