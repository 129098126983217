import React from 'react';
import { useState } from 'react';
import '../App.css';
import './TechnologiesCardsSwitch.css';
import amazon from '../img/aws.png';
import azure from '../img/azure.png';
import googlecloud from '../img/googlecloud.png';

export default function TechnologiesCardsSwitch() {
  function onSelectedItemChanged(num) {
    debugger;
    if (num === selectedItem) {
      setSelected(null);
    } else {
      setSelected(num);
    }
  }

  const [selectedItem, setSelected] = useState(null);
  return (
    <>
      <div
        className="amazon_block cursor-pointer"
        onClick={() => onSelectedItemChanged(0)}
      >
        {selectedItem == 0 ? (
          <>
            <div className="technologiesCardsSwitchBloc">
              <p className="technologiesCardsSwitchText">
                Amazon Web Services (AWS) is a cloud platform that provides a
                wide range of services for computing, data storage, analytics,
                artificial intelligence, the Internet of Things, and many
                others. AWS allows companies to use virtual servers, store data
                in cloud storage, build and deploy applications without
                significant infrastructure costs. This helps increase
                scalability, reduce costs and focus on business development.
              </p>
            </div>
          </>
        ) : (
          <>
            <img src={amazon} className="amazon_img" />
            <p className="cloud_text">Amazon Web Services</p>
          </>
        )}
      </div>
      <div
        className="azure_block cursor-pointer"
        onClick={() => onSelectedItemChanged(1)}
      >
        {selectedItem == 1 ? (
          <>
            <div className="technologiesCardsSwitchBloc">
              <p className="technologiesCardsSwitchText">
                Microsoft Azure is a cloud platform from Microsoft that provides
                various computing, storage, analytical and other services over
                the Internet. Azure enables businesses to build, deploy, and
                manage applications and services without incurring high hardware
                and maintenance costs. The platform offers scalability
                flexibility, data security and availability, as well as
                integration with other popular services and tools. Azure opens
                up opportunities to develop, test and implement innovative
                solutions without a lot of technical baggage.
              </p>
            </div>
          </>
        ) : (
          <>
            <img src={azure} className="azure_img" />
            <p className="cloud_text">microsoft azure</p>
          </>
        )}
      </div>
      <div
        className="cloud_block cursor-pointer"
        onClick={() => onSelectedItemChanged(2)}
      >
        {selectedItem == 2 ? (
          <>
            <div className="technologiesCardsSwitchBloc">
              <p className="technologiesCardsSwitchText">
                Google Cloud Platform (GCP) is a cloud platform from Google that
                provides computing resources, data storage and other services
                over the Internet. GCP enables enterprises to develop, deploy,
                and manage applications and services without large investments
                in physical hardware. The platform has flexible scalability,
                data protection and high availability. GCP provides
                opportunities for the development of artificial intelligence,
                data analysis and other innovative solutions using the powerful
                resources of Google.
              </p>
            </div>
          </>
        ) : (
          <>
            <img src={googlecloud} className="googlecloud_img" />
            <p className="cloud_text">Google Cloud platform</p>
          </>
        )}
      </div>
    </>
  );
}
